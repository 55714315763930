<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Filters @filters="setFilters" />
    <div class="row">
      <div class="col-lg-2">
        <TotalRegister :filters="filters"></TotalRegister>
      </div>
      <div class="col-lg-10">
        <HourTrend :filters="filters"></HourTrend>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <DonutRegister :filters="filters"></DonutRegister>
      </div>
      <div class="col-lg-8">
        <BarRegister :filters="filters"></BarRegister>
      </div>
    </div>
    <div>
      <GraphRegister :filters="filters"></GraphRegister>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <StateWiseCount :filters="filters"></StateWiseCount>
      </div>
      <div class="col-lg-4">
        <CityWiseCount :filters="filters"></CityWiseCount>
      </div>
      <div class="col-lg-4">
        <SpecialityWiseCount :filters="filters"></SpecialityWiseCount>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <DropOutUsers :filters="filters"></DropOutUsers>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-8">
        <!-- <Stat :optedIn="filters.opted_in" :summary="summary" :previousSummary="previousSummary" :totalDoctorsContacted="totalDoctorsContacted" :totalDoctorsAllocated="totalDoctorsAllocated"/>
        <CallAnalytics :buckets="call_duration_buckets" @analytics_filters="setAnalyticsFilters" /> -->
      </div>
      <div class="col-xl-4">
        <!-- <CallStatusAnalytics :summary="summary" /> -->
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <!-- <Transaction :filters="params" /> -->
      </div>
      <div class="col-lg-6">
        <!-- <DemographicCallStatusAnalytics :summary="state_summary" /> -->
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import GraphRegister from "./GraphRegister";
import HourTrend from "./HourTrend";
import TotalRegister from "./TotalRegister.vue";
import DonutRegister from "./DonutRegister.vue";
import BarRegister from "./BarRegister.vue";
import Filters from "../live-registration/filters.vue";
import StateWiseCount from "./StateWiseCount.vue";
import CityWiseCount from "./CityWiseCount.vue";
import SpecialityWiseCount from "./SpecialityWiseCount.vue";
import DropOutUsers from "./DropOutUsers.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Filters,
    GraphRegister,
    HourTrend,
    TotalRegister,
    DonutRegister,
    BarRegister,
    StateWiseCount,
    CityWiseCount,
    SpecialityWiseCount,
    DropOutUsers,
  },
  data() {
    return {
      title: "User Registrations",
      items: [
        {
          text: "Medisage",
        },
        {
          text: "User Registrations",
          active: true,
        },
      ],
      summary: {},
      previousSummary: {},
      state_summary: {},
      filters: {},
      reload: 0,
      params: {},
      doctors_contacted: 0,
      loading: true,
    };
  },
  created() {
    // this.init();
  },
  methods: {
    init() {
      // this.loading = true;
      // this.getCallSummery();
      // this.getDoctorsCount();
      // this.getCallDurationBuckets();
      // this.getStateCallSummery();
      // this.loading = false;
    },
    setFilters(v) {
      this.filters = v;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        // this.init();
      },
    },
  },
};
</script>
